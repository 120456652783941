import { getGameConfig, getImages, getText } from "../helper/Helper";
import DropDownWithScroll from "./DropDownWithScroll.js";
import "../css/Information.css";
import ButtonComponent from "./ButtonComponent";
import { useState } from "react";

const Information = (props) => {
  const [dropDown_1_selected, setDropDown_1_selected] = useState(null);
  const [dropDown_2_selected, setDropDown_2_selected] = useState(null);

  let gameConfig = getGameConfig();
  let dropDown_1_list = gameConfig.informationPageData.dropDown_1.map((item) => getText(item));
  let dropDown_2_list = gameConfig.informationPageData.dropDown_2.map((item) => getText(item));

  function enterHandler() {
    props.onEnterClick({ dropDown_1: dropDown_1_selected, dropDown_2: dropDown_2_selected });
  }

  return (
    <div className="Information" style={{ backgroundImage: `url(${getImages("home_bg")})` }}>
      <img src={getImages("info_page_title")} className="mainHeading"></img>
      <div className="dropDownBox" style={{ marginTop: "40px" }}>
        <div className="dropDownLabel">{getText("dropDownTitle1")}</div>
        <div>
          <DropDownWithScroll
            items={dropDown_1_list}
            left="27%"
            onSelect={(selected) => setDropDown_1_selected(selected)}
          ></DropDownWithScroll>
        </div>
      </div>
      <div className="dropDownBox" style={{ marginTop: "25px" }}>
        <div className="dropDownLabel">{getText("dropDownTitle2")}</div>
        <div>
          <DropDownWithScroll
            items={dropDown_2_list}
            left="26%"
            onSelect={(selected) => setDropDown_2_selected(selected)}
          ></DropDownWithScroll>
        </div>
      </div>
      <ButtonComponent
        text={getText("enter")}
        height="85px"
        width="311px"
        type="text"
        styleType="submit"
        top="87%"
        left="auto"
        clickHandler={enterHandler}
        disabled={
          dropDown_1_selected === getText("dropDownTitle") ||
          dropDown_2_selected === getText("dropDownTitle")
        }
      ></ButtonComponent>
    </div>
  );
};

export default Information;
