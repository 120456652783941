import React, { useState } from 'react';

const DropDownEntry = (props) => {
    const [hovered,setHovered] = useState(false);
    return (
        <div
        className={`listItem ${hovered ? 'hoverClass' : ''}`}
        onClick={() => {
            props.setSelected(props.item);
            props.setIsOpen(!props.isOpen);
        }}
        onMouseOver={()=>{setHovered(true)}}
        onMouseLeave={()=>{setHovered(false)}}
      >
        {props.item}
      </div>
    );
}

export default DropDownEntry;
