import React, { useEffect, useRef, useState } from "react";
import "../css/DropDownWithScroll.css";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import { getImages, getText } from "../helper/Helper";
import DropDownEntry from "./DropDownEntry";

require("jquery-mousewheel");

const DropDownWithScroll = (props) => {
  const { items } = props;
  const [selected, setSelected] = useState(getText("dropDownTitle"));
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  let imgSrc = getImages("dropdown_triangle");

  const closeOpenDropDown = (e) => {
    if (dropDownRef.current && isOpen && !dropDownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    props.onSelect(selected);
  }, [selected]);

  document.addEventListener("mousedown", closeOpenDropDown);
  // const [arrowUrl,setArrowUrl] = useState(items);
  return (
    <div
      className="dropDownContainer"
      style={{ top: props.top, left: props.left }}
      ref={dropDownRef}
    >
      <div
        className="dropDownHeader"
        onClick={() => {
          setIsOpen(!isOpen);
          setTimeout(() => {
            $("#listContainer").mCustomScrollbar({
              autoHideScrollbar: false,
            });

            for (let i = 0; i < 3; i++) {
              $("<div>", { class: "mCSB_dragger_innerLines" }).appendTo(".mCSB_dragger_bar");
            }
          }, 10);
        }}
      >
        <div className="headerText">{selected}</div>
        <img src={imgSrc} height="48px" width="48px" className="headerTriangle"></img>
      </div>
      {isOpen && (
        // <Scrollbars
        //   style={{ width: "340px", height: "180px", top: "-5px", zIndex: "-1" }}
        //   hideTracksWhenNotNeeded={true}
        //   renderView={(props) => <div {...props} className="view" />}
        //   renderTrackVertical={(props) => (
        //     <div {...props} className="track-vertical" />
        //   )}
        //   renderThumbVertical={(props) => {
        //     return (
        //       <div {...props} className="thumb-vertical">
        //         <div className="thumbLine" style={{ top: "45%" }}></div>
        //         <div className="thumbLine" style={{ top: "48%" }}></div>
        //         <div className="thumbLine" style={{ top: "51%" }}></div>
        //       </div>
        //     );
        //   }}
        // >
        <div id="listContainer" className="dropDownListContainer">
          {items.map((item, index) => {
            return (
              <DropDownEntry
                item={item}
                setSelected={setSelected}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                index={index}
                key={`listEntry_${index}`}
              ></DropDownEntry>
            );
          })}
        </div>
        /* </Scrollbars> */
      )}
    </div>
  );
};

export default DropDownWithScroll;
